import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export const createErrorObject = (title, msg) => {
  return {
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: title,
      icon: 'AlertCircleIcon',
      variant: "danger",
      text: msg,
    },
  }
}

export const createSuccessObject = (title, msg) => {
  return {
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title: title,
      icon: 'CheckIcon',
      variant: "primary",
      text: msg,
    },
  }
}

export const getFullname = (userData) => {
  const firstName = userData.firstName === null ? '' : userData.firstName
  const lastName = userData.lastName === null ? '' : userData.lastName
  if (firstName === '' && lastName === '') {
    return userData.email
  } else {
    return firstName + ' ' + lastName
  }
}