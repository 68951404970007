
import axios from 'axios'

import { getUser } from '@/graphql/queries'
import { createErrorObject, createSuccessObject } from '@/utils' 
import { updateUser } from '@/graphql/mutations'


const getTenantSubdomain = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'test-domain-1'
  } else {
    const url = window.location.href
    const res = url.split('//')
    const domain = res[1]
    const subdomain = domain.split('.')[0]
    return subdomain
  }
};

const actions = {
  async login ({ commit }, { email, password, toast, router }) {
    try {
      const user = await Auth.signIn(email, password);
      const getUserRes = await API.graphql(graphqlOperation(getUser, {
        id: user.attributes.sub
      }))
      if (getUserRes.data.getUser.profileImage) {
        const url = await Storage.get(getUserRes.data.getUser.profileImage)
        getUserRes.data.getUser.profileImageUrl = url
      }
      commit('SET_USER', getUserRes.data.getUser)
      toast(createSuccessObject('Success', 'Login success'))
      router.push({ name: 'pages-community' }).catch(() => {})
    } catch (err) {
      console.log(err);
      toast(createErrorObject('Error', err.message));
    }
  },
  async signUp ({ commit }, { email, password, toast, router }) {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          'custom:tenant': getTenantSubdomain()
        }
      })
      commit('SET_EMAIL', email)
    } catch (err) {
      console.log(err)
    }
  },
  async resendVerifyCode ({ commit }, { email }) {
    try {
      await Auth.resendSignUp(email)
    } catch (err) {
      console.log(err)
    }
  },
  async verifyCode ({ commit }, { email, code }) {
    try {
      Auth.confirmSignUp(email, code, {
        clientMetadata: {
          tenantSubdomain: getTenantSubdomain()
        }
      })
    } catch (err) {
      console.log(err)
    }
  },
  forgotPassword ({ commit }, { email }) {
    Auth.forgotPassword(email)
      .then(data => console.log(data))
      .catch(err => console.log(err))
  },
  forgotPasswordSubmit ({ commit }, { email, code, newPassword }) {
    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(data => console.log(data))
      .catch(err => console.log(err))
  },
  async logout ({ commit }, { router }) {
    try {
      await Auth.signOut()
      localStorage.removeItem('userData')
      commit('SET_USER', {})
      router.push({name: 'index'})
    } catch (err) {
      console.log(err)
    }
  },
  async updateUser ({ commit, state }, { firstName, lastName, profileImage, industry, location, jobPosition, company }) {
    try {
      let uploadedImage = ''
      if (profileImage) {
        uploadedImage = await Storage.put(profileImage.name, profileImage)
      }

      
      let input = ''
      if (uploadedImage !== '') {
        input = {
          id: state.user.id,
          firstName,
          lastName,
          industry,
          location,
          jobPosition,
          company,
          profileImage: uploadedImage.key
        }
      } else {
        input = {
          id: state.user.id,
          firstName,
          lastName,
          industry,
          location,
          jobPosition,
          company
        }
      }

      const updateRes = await API.graphql(graphqlOperation(updateUser, {
        input
      }))

      if (updateRes.data.updateUser.profileImage) {
        const url = await Storage.get(updateRes.data.updateUser.profileImage)
        updateRes.data.updateUser.profileImageUrl = url
      }
      
      commit('SET_USER', updateRes.data.updateUser)
    } catch (err) {
      console.log(err)
    }
  },
 
}

export  default actions