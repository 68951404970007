/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const createPersonalInfo = /* GraphQL */ `
  mutation CreatePersonalInfo(
    $input: CreatePersonalInfoInput!
    $condition: ModelPersonalInfoConditionInput
  ) {
    createPersonalInfo(input: $input, condition: $condition) {
      id
      userId
      birthday
      mobile
      gender
      address_1
      address_2
      postCode
      city
      state
      country
      createdAt
      updatedAt
    }
  }
`;
export const updatePersonalInfo = /* GraphQL */ `
  mutation UpdatePersonalInfo(
    $input: UpdatePersonalInfoInput!
    $condition: ModelPersonalInfoConditionInput
  ) {
    updatePersonalInfo(input: $input, condition: $condition) {
      id
      userId
      birthday
      mobile
      gender
      address_1
      address_2
      postCode
      city
      state
      country
      createdAt
      updatedAt
    }
  }
`;
export const deletePersonalInfo = /* GraphQL */ `
  mutation DeletePersonalInfo(
    $input: DeletePersonalInfoInput!
    $condition: ModelPersonalInfoConditionInput
  ) {
    deletePersonalInfo(input: $input, condition: $condition) {
      id
      userId
      birthday
      mobile
      gender
      address_1
      address_2
      postCode
      city
      state
      country
      createdAt
      updatedAt
    }
  }
`;
export const createSocialInfo = /* GraphQL */ `
  mutation CreateSocialInfo(
    $input: CreateSocialInfoInput!
    $condition: ModelSocialInfoConditionInput
  ) {
    createSocialInfo(input: $input, condition: $condition) {
      id
      userId
      twitter
      facebook
      instagram
      linkedin
      youtube
      website
      createdAt
      updatedAt
    }
  }
`;
export const updateSocialInfo = /* GraphQL */ `
  mutation UpdateSocialInfo(
    $input: UpdateSocialInfoInput!
    $condition: ModelSocialInfoConditionInput
  ) {
    updateSocialInfo(input: $input, condition: $condition) {
      id
      userId
      twitter
      facebook
      instagram
      linkedin
      youtube
      website
      createdAt
      updatedAt
    }
  }
`;
export const deleteSocialInfo = /* GraphQL */ `
  mutation DeleteSocialInfo(
    $input: DeleteSocialInfoInput!
    $condition: ModelSocialInfoConditionInput
  ) {
    deleteSocialInfo(input: $input, condition: $condition) {
      id
      userId
      twitter
      facebook
      instagram
      linkedin
      youtube
      website
      createdAt
      updatedAt
    }
  }
`;
export const createWorkExperience = /* GraphQL */ `
  mutation CreateWorkExperience(
    $input: CreateWorkExperienceInput!
    $condition: ModelWorkExperienceConditionInput
  ) {
    createWorkExperience(input: $input, condition: $condition) {
      id
      userId
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkExperience = /* GraphQL */ `
  mutation UpdateWorkExperience(
    $input: UpdateWorkExperienceInput!
    $condition: ModelWorkExperienceConditionInput
  ) {
    updateWorkExperience(input: $input, condition: $condition) {
      id
      userId
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkExperience = /* GraphQL */ `
  mutation DeleteWorkExperience(
    $input: DeleteWorkExperienceInput!
    $condition: ModelWorkExperienceConditionInput
  ) {
    deleteWorkExperience(input: $input, condition: $condition) {
      id
      userId
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      tenantId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      videoUrl
      likesCount
      likes {
        items {
          id
          postId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      createdAt
      updatedAt
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      tenantId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      videoUrl
      likesCount
      likes {
        items {
          id
          postId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      createdAt
      updatedAt
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      tenantId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      videoUrl
      likesCount
      likes {
        items {
          id
          postId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const createSubcomment = /* GraphQL */ `
  mutation CreateSubcomment(
    $input: CreateSubcommentInput!
    $condition: ModelSubcommentConditionInput
  ) {
    createSubcomment(input: $input, condition: $condition) {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateSubcomment = /* GraphQL */ `
  mutation UpdateSubcomment(
    $input: UpdateSubcommentInput!
    $condition: ModelSubcommentConditionInput
  ) {
    updateSubcomment(input: $input, condition: $condition) {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteSubcomment = /* GraphQL */ `
  mutation DeleteSubcomment(
    $input: DeleteSubcommentInput!
    $condition: ModelSubcommentConditionInput
  ) {
    deleteSubcomment(input: $input, condition: $condition) {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createLikePost = /* GraphQL */ `
  mutation CreateLikePost(
    $input: CreateLikePostInput!
    $condition: ModelLikePostConditionInput
  ) {
    createLikePost(input: $input, condition: $condition) {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateLikePost = /* GraphQL */ `
  mutation UpdateLikePost(
    $input: UpdateLikePostInput!
    $condition: ModelLikePostConditionInput
  ) {
    updateLikePost(input: $input, condition: $condition) {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLikePost = /* GraphQL */ `
  mutation DeleteLikePost(
    $input: DeleteLikePostInput!
    $condition: ModelLikePostConditionInput
  ) {
    deleteLikePost(input: $input, condition: $condition) {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createLikeComment = /* GraphQL */ `
  mutation CreateLikeComment(
    $input: CreateLikeCommentInput!
    $condition: ModelLikeCommentConditionInput
  ) {
    createLikeComment(input: $input, condition: $condition) {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateLikeComment = /* GraphQL */ `
  mutation UpdateLikeComment(
    $input: UpdateLikeCommentInput!
    $condition: ModelLikeCommentConditionInput
  ) {
    updateLikeComment(input: $input, condition: $condition) {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLikeComment = /* GraphQL */ `
  mutation DeleteLikeComment(
    $input: DeleteLikeCommentInput!
    $condition: ModelLikeCommentConditionInput
  ) {
    deleteLikeComment(input: $input, condition: $condition) {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createLikeSubComment = /* GraphQL */ `
  mutation CreateLikeSubComment(
    $input: CreateLikeSubCommentInput!
    $condition: ModelLikeSubCommentConditionInput
  ) {
    createLikeSubComment(input: $input, condition: $condition) {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateLikeSubComment = /* GraphQL */ `
  mutation UpdateLikeSubComment(
    $input: UpdateLikeSubCommentInput!
    $condition: ModelLikeSubCommentConditionInput
  ) {
    updateLikeSubComment(input: $input, condition: $condition) {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteLikeSubComment = /* GraphQL */ `
  mutation DeleteLikeSubComment(
    $input: DeleteLikeSubCommentInput!
    $condition: ModelLikeSubCommentConditionInput
  ) {
    deleteLikeSubComment(input: $input, condition: $condition) {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createFollow = /* GraphQL */ `
  mutation CreateFollow(
    $input: CreateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    createFollow(input: $input, condition: $condition) {
      id
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      followerId
      followerRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFollow = /* GraphQL */ `
  mutation UpdateFollow(
    $input: UpdateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    updateFollow(input: $input, condition: $condition) {
      id
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      followerId
      followerRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFollow = /* GraphQL */ `
  mutation DeleteFollow(
    $input: DeleteFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    deleteFollow(input: $input, condition: $condition) {
      id
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      followerId
      followerRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
