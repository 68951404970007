
const mutations = {
  SET_USER (state, payload) {
    const userData = JSON.parse(localStorage.getItem('userData')) || {}
    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        state.user[property] = payload[property]
        userData[property] = payload[property]
      }
    }
    localStorage.setItem('userData', JSON.stringify(userData))
    state.user = payload
  },
  SET_EMAIL (state, payload) {
    state.email = payload
  },
  SET_PRIVACY_URL (state, val) {
    state.privacy_url = val
  },
  SET_WALLET_CONNECTION(state,val){
    state.isConnected=val
  },
 
}

export default mutations
