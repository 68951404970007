import { render, staticRenderFns } from "./PWAPrompt.vue?vue&type=template&id=54434ecd&scoped=true"
import script from "./PWAPrompt.vue?vue&type=script&lang=js"
export * from "./PWAPrompt.vue?vue&type=script&lang=js"
import style0 from "./PWAPrompt.vue?vue&type=style&index=0&id=54434ecd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54434ecd",
  null
  
)

export default component.exports