import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { 
  BModal, 
  BButton, 
  BCol, 
  BRow, 
  BForm,
  BFormTextarea,
  BFormInput, 
  BFormFile,
  BFormGroup,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BImg,
  BBadge,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BTab,
  BTabs
} from 'bootstrap-vue'

Vue.component('b-modal', BModal)
Vue.component('b-button', BButton)
Vue.component('b-col', BCol)
Vue.component('b-row', BRow)

Vue.component('b-form', BForm)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-file', BFormFile)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)

Vue.component('b-avatar', BAvatar)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-link', BLink)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-input-group-prepend', BInputGroupPrepend)

Vue.component('b-card', BCard)
Vue.component('b-card-header', BCardHeader)
Vue.component('b-card-text', BCardText)
Vue.component('b-card-title', BCardTitle)
Vue.component('b-card-sub-title', BCardSubTitle)
Vue.component('b-card-body', BCardBody)

Vue.component('b-container', BContainer)
Vue.component('b-img', BImg)
Vue.component('b-badge', BBadge)
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)

Vue.component('b-tab', BTab)
Vue.component('b-tabs', BTabs)

Vue.component(FeatherIcon.name, FeatherIcon)
