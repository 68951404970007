const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('userData')) || {}
}

// const isAuthenticated = () => {
//   if (Auth.currentAuthenticatedUser || getCurrentUser()) {
//     return true
//   } else return false
// }

export default {
  user: getCurrentUser(),
  email: '',
  isConnected:false,

  privacy_url:''
}