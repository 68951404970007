/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSuggestedFollows = /* GraphQL */ `
  query GetSuggestedFollows($userId: ID) {
    getSuggestedFollows(userId: $userId) {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const getNewsfeed = /* GraphQL */ `
  query GetNewsfeed($userId: ID, $tenantId: ID, $nextToken: String) {
    getNewsfeed(userId: $userId, tenantId: $tenantId, nextToken: $nextToken) {
      items {
        id
        tenantId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        videoUrl
        likesCount
        likes {
          nextToken
        }
        commentsCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      tenantId
      profileImage
      firstName
      lastName
      about
      headline
      industry
      jobPosition
      company
      location
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPersonalInfo = /* GraphQL */ `
  query GetPersonalInfo($id: ID!) {
    getPersonalInfo(id: $id) {
      id
      userId
      birthday
      mobile
      gender
      address_1
      address_2
      postCode
      city
      state
      country
      createdAt
      updatedAt
    }
  }
`;
export const listPersonalInfos = /* GraphQL */ `
  query ListPersonalInfos(
    $filter: ModelPersonalInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonalInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        birthday
        mobile
        gender
        address_1
        address_2
        postCode
        city
        state
        country
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSocialInfo = /* GraphQL */ `
  query GetSocialInfo($id: ID!) {
    getSocialInfo(id: $id) {
      id
      userId
      twitter
      facebook
      instagram
      linkedin
      youtube
      website
      createdAt
      updatedAt
    }
  }
`;
export const listSocialInfos = /* GraphQL */ `
  query ListSocialInfos(
    $filter: ModelSocialInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        twitter
        facebook
        instagram
        linkedin
        youtube
        website
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkExperience = /* GraphQL */ `
  query GetWorkExperience($id: ID!) {
    getWorkExperience(id: $id) {
      id
      userId
      position
      company
      description
      location
      from
      to
      createdAt
      updatedAt
    }
  }
`;
export const listWorkExperiences = /* GraphQL */ `
  query ListWorkExperiences(
    $filter: ModelWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkExperiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        position
        company
        description
        location
        from
        to
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      tenantId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      videoUrl
      likesCount
      likes {
        items {
          id
          postId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      commentsCount
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tenantId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        videoUrl
        likesCount
        likes {
          nextToken
        }
        commentsCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      postId
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      likesCount
      likes {
        items {
          id
          commentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      subCommentsCount
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubcomment = /* GraphQL */ `
  query GetSubcomment($id: ID!) {
    getSubcomment(id: $id) {
      id
      commentId
      commentRef {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      content
      images
      createdAt
      likesCount
      likes {
        items {
          id
          subCommentId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listSubcomments = /* GraphQL */ `
  query ListSubcomments(
    $filter: ModelSubcommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcomments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commentId
        commentRef {
          id
          postId
          userId
          content
          images
          likesCount
          subCommentsCount
          createdAt
          updatedAt
        }
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        createdAt
        likesCount
        likes {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikePost = /* GraphQL */ `
  query GetLikePost($id: ID!) {
    getLikePost(id: $id) {
      id
      postId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listLikePosts = /* GraphQL */ `
  query ListLikePosts(
    $filter: ModelLikePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikePosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikeComment = /* GraphQL */ `
  query GetLikeComment($id: ID!) {
    getLikeComment(id: $id) {
      id
      commentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listLikeComments = /* GraphQL */ `
  query ListLikeComments(
    $filter: ModelLikeCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikeComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        commentId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLikeSubComment = /* GraphQL */ `
  query GetLikeSubComment($id: ID!) {
    getLikeSubComment(id: $id) {
      id
      subCommentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listLikeSubComments = /* GraphQL */ `
  query ListLikeSubComments(
    $filter: ModelLikeSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikeSubComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subCommentId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFollow = /* GraphQL */ `
  query GetFollow($id: ID!) {
    getFollow(id: $id) {
      id
      userId
      userRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      followerId
      followerRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFollows = /* GraphQL */ `
  query ListFollows(
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        followerId
        followerRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      fromUserId
      fromUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      toUserId
      toUserRef {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      postId
      content
      isSeen
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fromUserId
        fromUserRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        toUserId
        toUserRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        postId
        content
        isSeen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByTenant = /* GraphQL */ `
  query UsersByTenant(
    $tenantId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByTenant(
      tenantId: $tenantId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const personalInfoByUserId = /* GraphQL */ `
  query PersonalInfoByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalInfoByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        birthday
        mobile
        gender
        address_1
        address_2
        postCode
        city
        state
        country
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const socialInfoByUserId = /* GraphQL */ `
  query SocialInfoByUserId(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSocialInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    socialInfoByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        twitter
        facebook
        instagram
        linkedin
        youtube
        website
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const workExperienceByUserId = /* GraphQL */ `
  query WorkExperienceByUserId(
    $userId: ID
    $from: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    workExperienceByUserId(
      userId: $userId
      from: $from
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        position
        company
        description
        location
        from
        to
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByUser = /* GraphQL */ `
  query PostsByUser(
    $userId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        videoUrl
        likesCount
        likes {
          nextToken
        }
        commentsCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByTenant = /* GraphQL */ `
  query PostsByTenant(
    $tenantId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByTenant(
      tenantId: $tenantId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tenantId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        videoUrl
        likesCount
        likes {
          nextToken
        }
        commentsCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const commentsByPost = /* GraphQL */ `
  query CommentsByPost(
    $postId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByPost(
      postId: $postId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        likesCount
        likes {
          nextToken
        }
        subCommentsCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subcommentsByComment = /* GraphQL */ `
  query SubcommentsByComment(
    $commentId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSubcommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subcommentsByComment(
      commentId: $commentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        commentRef {
          id
          postId
          userId
          content
          images
          likesCount
          subCommentsCount
          createdAt
          updatedAt
        }
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        createdAt
        likesCount
        likes {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const likesByPostId = /* GraphQL */ `
  query LikesByPostId(
    $postId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByPostId(
      postId: $postId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likePostsByUserId = /* GraphQL */ `
  query LikePostsByUserId(
    $userId: ID
    $postId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikePostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likePostsByUserId(
      userId: $userId
      postId: $postId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likesByCommentId = /* GraphQL */ `
  query LikesByCommentId(
    $commentId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByCommentId(
      commentId: $commentId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likeCommentsByUserId = /* GraphQL */ `
  query LikeCommentsByUserId(
    $userId: ID
    $commentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likeCommentsByUserId(
      userId: $userId
      commentId: $commentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        commentId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likesBySubCommentId = /* GraphQL */ `
  query LikesBySubCommentId(
    $subCommentId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesBySubCommentId(
      subCommentId: $subCommentId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subCommentId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const likeSubCommentsByUserId = /* GraphQL */ `
  query LikeSubCommentsByUserId(
    $userId: ID
    $subCommentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikeSubCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likeSubCommentsByUserId(
      userId: $userId
      subCommentId: $subCommentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subCommentId
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const followsByUserId = /* GraphQL */ `
  query FollowsByUserId(
    $userId: ID
    $followerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followsByUserId(
      userId: $userId
      followerId: $followerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        followerId
        followerRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const followsByFollowerId = /* GraphQL */ `
  query FollowsByFollowerId(
    $followerId: ID
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    followsByFollowerId(
      followerId: $followerId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        followerId
        followerRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notiByFromUserId = /* GraphQL */ `
  query NotiByFromUserId(
    $fromUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notiByFromUserId(
      fromUserId: $fromUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromUserId
        fromUserRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        toUserId
        toUserRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        postId
        content
        isSeen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notiByToUserId = /* GraphQL */ `
  query NotiByToUserId(
    $toUserId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notiByToUserId(
      toUserId: $toUserId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromUserId
        fromUserRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        toUserId
        toUserRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        postId
        content
        isSeen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        email
        tenantId
        profileImage
        firstName
        lastName
        about
        headline
        industry
        jobPosition
        company
        location
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchPosts = /* GraphQL */ `
  query SearchPosts(
    $filter: SearchablePostFilterInput
    $sort: SearchablePostSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPosts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        tenantId
        userId
        userRef {
          id
          email
          tenantId
          profileImage
          firstName
          lastName
          about
          headline
          industry
          jobPosition
          company
          location
          createdAt
          updatedAt
        }
        content
        images
        videoUrl
        likesCount
        likes {
          nextToken
        }
        commentsCount
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
